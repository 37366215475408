import React from "react"
import { useChat } from "./ChatProvider"
import {
  HStack,
  Circle,
  Text,
  Box,
  useBreakpointValue,
  Icon,
  IconButton,
  ButtonGroup,
  Flex,
  Image,
  Avatar,
  Button,
} from "@chakra-ui/react"

import { FaUserTie } from "react-icons/fa"

import { FiThumbsUp, FiThumbsDown } from "react-icons/fi"
import { useRealmApp } from "../../context/RealmApp"
import { useState } from "react"
import ContactForm from "./ContactForm"

import { BiErrorCircle } from "react-icons/bi"

function AssistantResponse({ transcript, onOpen }) {
  const {
    setPositiveFeedback,
    feedbackContent,
    setFeedbackContent,
    namespace,
    setScrollBottom,
  } = useChat() as any

  const { currentUser } = useRealmApp() as any
  const [showContactUsForm, setShowContactUsForm] = useState(false)
  const [showSaveTranscriptForm, setShowSaveTranscriptForm] = useState(false)

  const boxWidth = useBreakpointValue({ base: "100%", md: "60%" })
  const isMobile = useBreakpointValue({ base: true, md: false })
  const buttonSize = useBreakpointValue(["sm", "sm", "md"])

  const toggleForm = () => {
    setShowContactUsForm(true)
    setShowSaveTranscriptForm(false)
    setScrollBottom(prev => !prev)
  }

  const onSaveTranscriptForm = () => {
    setShowSaveTranscriptForm(true)
    setShowContactUsForm(false)
    setScrollBottom(prev => !prev)
  }

  const navigateToContact = () => {
    window.open("https://virtuzone.com/contactus", "_blank")
  }

  const UserInput = () => {
    return (
      <HStack
        w="full"
        h="full"
        position="relative"
        spacing={[4, 4, 8]}
        justifyContent="flex-start"
        minH="53px"
      >
        <Avatar
          bg="white"
          icon={
            <Icon
              as={FaUserTie}
              color="red.full"
              fontSize={["22px", "22px", "28px"]}
            />
          }
          size={["sm", "sm", "md"]}
        />

        <Box
          py={3}
          px={[4, 4, 12]}
          bg="red.100"
          roundedRight="20px"
          roundedBottomLeft="20px"
        >
          <Text
            flex="1"
            fontSize={["md", "md", "lg"]}
            sx={{
              whiteSpace: "pre-wrap",
            }}
          >
            {transcript.content}
          </Text>
        </Box>
      </HStack>
    )
  }

  const ChatVzInput = () => {
    return (
      <>
        <HStack
          w="full"
          h="full"
          position="relative"
          spacing={[4, 4, 8]}
          justifyContent="flex-end"
          alignItems="flex-start"
          minH="53px"
        >
          <Box
            py={4}
            px={[4, 4, 12]}
            bg="white"
            roundedLeft="20px"
            roundedBottomRight="20px"
            w="full"
          >
            <Text
              flex="1"
              fontSize={["md", "md", "lg"]}
              sx={{
                whiteSpace: "pre-wrap",
              }}
            >
              {transcript.content}
            </Text>

            <Flex justifyContent="flex-start">
              {currentUser?._profile?.data?.email && (
                <HStack pt="5" spacing={1}>
                  <IconButton
                    aria-label="Feedback positive"
                    icon={
                      <Icon as={FiThumbsUp} color="green.500" boxSize="20px" />
                    }
                    onClick={() => {
                      setPositiveFeedback(true)
                      setFeedbackContent({
                        ...feedbackContent,
                        response: transcript.content,
                      })
                      onOpen()
                    }}
                    size="md"
                  />
                  <IconButton
                    aria-label="Feedback positive"
                    icon={
                      <Icon as={FiThumbsDown} color="red.500" boxSize="20px" />
                    }
                    onClick={() => {
                      setPositiveFeedback(false)
                      setFeedbackContent({
                        ...feedbackContent,
                        response: transcript.content,
                      })
                      onOpen()
                    }}
                    size="md"
                  />
                </HStack>
              )}
            </Flex>

            <Flex justifyContent={"flex-end"}>
              <ButtonGroup
                pt="5"
                spacing={isMobile ? 0 : 4}
                justifyContent={isMobile ? "center" : "flex-end"}
                flexDirection={isMobile ? "column" : "row"}
                className="cta-link"
              >
                {transcript?.triggers?.costCalculatorBtn &&
                  transcript?.triggers?.costCalculatorBtn.show && (
                    <Button
                      bg="red.full"
                      zIndex="0"
                      textAlign="center"
                      size={buttonSize}
                      mb={2}
                      onClick={() => {
                        window.open(
                          transcript?.triggers?.costCalculatorBtn?.data?.target
                            ?.value,
                          "_blank"
                        )
                      }}
                    >
                      {transcript?.triggers?.costCalculatorBtn?.title}
                    </Button>
                  )}

                {transcript?.triggers?.contactFormBtn &&
                  transcript?.triggers?.contactFormBtn.show && (
                    <Button
                      onClick={navigateToContact}
                      bg="red.full"
                      size={buttonSize}
                      zIndex="0"
                      mb={2}
                      textAlign="center"
                    >
                      {transcript?.triggers?.contactFormBtn?.title}
                    </Button>
                  )}

                {transcript?.triggers?.ebookDownloadBtn &&
                  transcript?.triggers?.ebookDownloadBtn.show && (
                    <Button
                      bg="red.full"
                      zIndex="0"
                      mb={2}
                      size={buttonSize}
                      textAlign="center"
                      size={buttonSize}
                      onClick={() => {
                        window.open(
                          transcript?.triggers?.ebookDownloadBtn?.data?.target
                            ?.value,
                          "_blank"
                        )
                      }}
                    >
                      {transcript?.triggers?.ebookDownloadBtn?.title}
                    </Button>
                  )}

                {/* {transcript?.triggers?.saveTranscriptBtn &&
                  transcript?.triggers?.saveTranscriptBtn.show && (
                    <Button
                      bg="red.full"
                      onClick={onSaveTranscriptForm}
                      zIndex="0"
                      mb={2}
                      textAlign="center"
                    >
                      {transcript?.triggers?.saveTranscriptBtn?.title}
                    </Button>
                  )} */}
              </ButtonGroup>
            </Flex>
          </Box>

          <Circle size={["32px", "32px", "44px"]} overflow="hidden">
            <Image
              src="https://res.cloudinary.com/wild-creative/image/upload/v1700034710/chatvz_fjim7f.svg"
              rounded="full"
              boxShadow="rgba(207, 51, 58, 0.1) 0px 10px 20px"
            />
          </Circle>
        </HStack>

        {/* {showSaveTranscriptForm && (
          <HStack
            w="full"
            h="full"
            position="relative"
            spacing={[4, 4, 8]}
            justifyContent="flex-end"
            alignItems="flex-end"
            minH="53px"
          >
            <Box
              py={5}
              px={6}
              bg="white"
              roundedLeft="20px"
              roundedBottomRight="20px"
              w={boxWidth}
            >
              <SaveTranscript />
            </Box>

            {namespace?.chatIcon?.show && (
              <Circle
                bg="red.full"
                aria-label={namespace?.chatIcon?.title}
                size={["32px", "32px", "44px"]}
                fontWeight="bold"
                color="white"
                boxShadow="rgba(207, 51, 58, 0.1) 0px 10px 20px"
              >
                {namespace?.chatIcon?.label}
              </Circle>
            )}
          </HStack>
        )} */}

        {showContactUsForm && (
          <HStack
            w="full"
            h="full"
            position="relative"
            spacing={[4, 4, 8]}
            justifyContent="flex-end"
            alignItems="flex-end"
            minH="53px"
          >
            <Box
              py={[6, 6, 16]}
              px={[6, 6, 16]}
              bg="white"
              roundedLeft="20px"
              roundedBottomRight="20px"
              w="full"
            >
              <ContactForm />
            </Box>

            {namespace?.chatIcon?.show && (
              <Circle size={["32px", "32px", "44px"]} overflow="hidden">
                <Image
                  src="https://res.cloudinary.com/wild-creative/image/upload/v1700034710/chatvz_fjim7f.svg"
                  rounded="full"
                  boxShadow="rgba(207, 51, 58, 0.1) 0px 10px 20px"
                />
              </Circle>
            )}
          </HStack>
        )}
      </>
    )
  }

  const ErrorInput = () => {
    return (
      <>
        <HStack
          w="full"
          h="full"
          position="relative"
          spacing={[4, 4, 8]}
          justifyContent="flex-end"
          alignItems="flex-end"
          minH="53px"
        >
          <Box
            py={4}
            px={[4, 4, 12]}
            bg="#FBECEF"
            roundedLeft="20px"
            roundedBottomRight="20px"
            border={"1px solid #F74040"}
            w="full"
          >
            <Flex align="center">
              <Icon
                as={BiErrorCircle}
                boxSize={6}
                mr={"33px"}
                color="#F64040"
                w={"33px"}
                h={"33px"}
              />
              <Text
                flex="1"
                fontSize={"18px"}
                color={"#F64040"}
                fontStyle={"normal"}
                lineHeight={"34px"}
                fontWeight={"500"}
              >
                {transcript.content}
              </Text>
            </Flex>
            <Flex justifyContent={"flex-end"}>
              <ButtonGroup
                spacing={isMobile ? 0 : 4}
                justifyContent={isMobile ? "center" : "flex-end"}
                flexDirection={isMobile ? "column" : "row"}
              >
                <Button
                  onClick={toggleForm}
                  bg="red.full"
                  zIndex="0"
                  mb={2}
                  textAlign="center"
                >
                  Contact Us
                </Button>
              </ButtonGroup>
            </Flex>
          </Box>

          {namespace?.chatIcon?.show && (
            <Circle size={["32px", "32px", "44px"]} overflow="hidden">
              <Image
                src="https://res.cloudinary.com/wild-creative/image/upload/v1700034710/chatvz_fjim7f.svg"
                rounded="full"
                boxShadow="rgba(207, 51, 58, 0.1) 0px 10px 20px"
              />
            </Circle>
          )}
        </HStack>

        {showContactUsForm && (
          <HStack
            w="full"
            h="full"
            position="relative"
            spacing={[4, 4, 8]}
            justifyContent="flex-end"
            alignItems="flex-end"
            minH="53px"
          >
            <Box
              py={5}
              px={6}
              bg="white"
              roundedLeft="20px"
              roundedBottomRight="20px"
              w={boxWidth}
            >
              <ContactForm transcript={transcript} />
            </Box>

            {namespace?.chatIcon?.show && (
              <Circle size={["32px", "32px", "44px"]} overflow="hidden">
                <Image
                  src="https://res.cloudinary.com/wild-creative/image/upload/v1700034710/chatvz_fjim7f.svg"
                  rounded="full"
                  boxShadow="rgba(207, 51, 58, 0.1) 0px 10px 20px"
                />
              </Circle>
            )}
          </HStack>
        )}
      </>
    )
  }

  return (
    <>
      {transcript.role === "user" && <UserInput />}
      {transcript.role === "error" && <ErrorInput />}
      {transcript.role === "assistant" && <ChatVzInput />}
    </>
  )
}

export default AssistantResponse
