import React from "react"
import {
  Box,
  Flex,
  Grid,
  Link,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react"
import { useChat } from "../chat/ChatProvider"

function Footer() {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const { namespace } = useChat()
  return (
    <>
      {isMobile ? (
        <Box as="footer" pt="0px" w="full">
          <Text fontSize="9px" color="black.500" dangerouslySetInnerHTML={{ __html: namespace?.desclaimer?.mobile }}>
          </Text>
        </Box>
      ) : (
          <Box as="footer" pt={["15px", "0", "10px"]} w="full">
            <Grid
              templateColumns={{ base: "1fr", md: "3fr 2fr" }}
              gap={4}
            >
              <Box>
                {
                  namespace &&
                  <Text fontSize="9px" color="black.500" dangerouslySetInnerHTML={{ __html: namespace?.desclaimer?.short }}>
                  </Text>
                }
              </Box>
              <Flex
                flexDirection="column"
                alignItems={{ base: "flex-start", md: "flex-end" }}
              >
                <Flex justifyContent={{ base: "flex-start", md: "flex-end" }}>
                  <Text fontSize="10px" color="black.500">
                    <Link
                      href="/termofuse"
                      color="black.500"
                      textDecoration="underline"
                      mr={2}
                      isExternal={true}
                    >
                      Privacy Policy
                  </Link>
                  </Text>
                  <Text fontSize="10px" color="black.500">
                    |
                </Text>
                  <Text fontSize="10px" color="black.500">
                    <Link
                      target="_blank"
                      href="https://www.vz.ae/contactus/"
                      color="black.500"
                      textDecoration="underline"
                      ml={2}
                    >
                      Contact Us
                  </Link>
                  </Text>
                </Flex>
                <Text fontSize="9px" color="black.500" textAlign="right" mt={2}>
                  <Link color="black.500" textDecoration="underline">
                    © Virtuzone 2023
                </Link>
                </Text>
              </Flex>
            </Grid>
          </Box>
        )}
    </>
  )
}

export default Footer
