import Section from "gatsby-theme-wild-child/src/components/Section"
import React, { useEffect, useRef } from "react"

import TypingAnimation from "./TypingAnimation"
import { useChat } from "./ChatProvider"
import Response from "./Response"
import Clear from "./Clear"
import UserInput from "./UserInput"
import { useTheme, VStack, Grid, GridItem } from "@chakra-ui/react"

function Transcript({ onOpen }) {
  const { transcriptHistory, isListening, scrollBottom } = useChat()
  const { variables } = useTheme()
  const listRef: any = useRef(null)

  useEffect(() => {
    scrollToBottom()
  }, [transcriptHistory, scrollBottom])

  const scrollToBottom = () => {
    if (listRef !== null && listRef.current !== null) {
      listRef.current.scrollTo({
        top: listRef.current.scrollHeight,
        behavior: "smooth",
      })
    }
  }

  return (
    <Section
      bg="gray.full"
      minH="100vh"
      maxH="100vh"
      h="100vh"
      withContainer={false}
      overflowY="hidden"
    >
      <Grid
        h="100%"
        templateRows={["1fr 110px", "1fr 110px", "1fr 130px"]}
        templateColumns="1"
        w="full"
        maxW="1180px"
        pt={[24, 24, 24]}
        gridGap={8}
      >
        <GridItem overflowY="scroll" ref={listRef} py={[12]} pr={[0, 0, 6]}>
          <VStack
            w="full"
            maxW={variables.maxContentWidth}
            spacing={[8, 8, 12]}
            py={[8, 8, 20]}
            px={0}
          >
            {transcriptHistory.map((transcript, index) => (
              <Response transcript={transcript} key={index} onOpen={onOpen} />
            ))}

            {isListening && <TypingAnimation />}
          </VStack>
        </GridItem>
        <GridItem bg="gray.full">
          <UserInput showTopPrompts={false} />
        </GridItem>
      </Grid>
      <Clear
        position="fixed"
        top={["100px", "100px", "120px"]}
        left={["15px", "20px", "40px"]}
        zIndex="99999"
      />
    </Section>
  )
}

export default Transcript
