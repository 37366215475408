import React, { useContext, useEffect } from "react"
import { IoMdSend } from "react-icons/io"
import { useChat } from "./ChatProvider"
import { AiOutlineSearch } from "react-icons/ai"
import { SiOpenai } from "react-icons/si"
import ReferenceChecks from "./ReferenceChecks"
import { useRealmApp } from "../../context/RealmApp"
import { AppContext } from "src/gatsby-theme-wild-child/context/ContextProvider"
import Footer from "../common/Footer"
import {
  Center,
  Text,
  InputGroup,
  Input,
  InputRightAddon,
  IconButton,
  InputLeftAddon,
  Icon,
  HStack,
  VStack,
  Tooltip,
  useBreakpointValue,
  SimpleGrid,
  Button,
  Box,
} from "@chakra-ui/react"

function UserInput(props) {
  const { showTopPrompts } = props
  const { namespace } = useChat()

  const {
    handleSubmit,
    isListening,
    query,
    setQuery,
    setFocused,
    focused,
    gpt4,
    setGpt4,
  } = useChat()

  const buttonSize = useBreakpointValue(["md", "md", "lg"])
  const isMobile = useBreakpointValue({ base: true, md: false })

  const { loading } = useContext(AppContext)
  const { currentUser } = useRealmApp() as any

  useEffect(() => {
    const handleKeyDown = e => {
      if (!focused || isListening) return
      if (e.key === "Enter") {
        handleSubmit()
      }
    }

    window.addEventListener("keydown", handleKeyDown)
    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [focused, query])

  return (
    <Box w="full">
      {showTopPrompts && (
        <VStack w="full" my={["1rem", "2rem", "2rem"]}>
          <Text
            color="dark.700"
            fontSize={["16px", "16px", "18px", "18px", "18px", "18px", "21px"]}
          >
            {namespace?.action}
          </Text>
          <SimpleGrid
            gap={4}
            columns={[1, 1, 2, 2, 2, 4]}
            pb={[2, 2, 0, 0]}
            w={["88%", "88%", "full"]}
          >
            {namespace?.types.map((action, index) => (
              <Button
                key={index}
                bg="red.200"
                color="red.full"
                size={buttonSize}
                onClick={() => {
                  if (action.target === null) {
                    handleSubmit(action.key)
                  } else {
                    window.open(action.target.link, "_blank", "noreferrer")
                  }
                }}
                className="prompt-button"
              >
                {action.label}
              </Button>
            ))}
          </SimpleGrid>
        </VStack>
      )}
      <SimpleGrid columns={1} zIndex="1" bottom="0" left="0">
        <Box>
          <Center
            pb={[0, 0, 6]}
            mb={!showTopPrompts && !isMobile ? "50px" : "0px"}
          >
            <HStack w="full">
              <InputGroup
                rounded="lg"
                border={isMobile ? "none" : "1px solid"}
                borderColor="gray.100"
                boxShadow="rgba(207, 51, 58, 0.1) 0px 10px 50px"
                h={["65px", "65px", "80px"]}
              >
                <InputLeftAddon
                  height="full"
                  padding="0"
                  bg="white"
                  h="full"
                  children={
                    <IconButton
                      aria-label="search icon"
                      pointerEvents="none"
                      icon={
                        <Icon
                          color="dark.200"
                          as={AiOutlineSearch}
                          boxSize="25px"
                        />
                      }
                    />
                  }
                />
                <Input
                  placeholder={namespace?.promptTitle}
                  _placeholder={{
                    color: "dark.400",
                    fontSize: ["md", "md", "lg"],
                  }}
                  fontSize={["md", "md", "lg"]}
                  pl={4}
                  size="xl"
                  h="full"
                  value={query}
                  onChange={e => setQuery(e.target.value)}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  isDisabled={isListening || loading}
                  _disabled={{ bg: "white" }}
                />
                <InputRightAddon
                  height="full"
                  padding="0"
                  bg="white"
                  h="full"
                  children={
                    <IconButton
                      aria-label="chat"
                      onClick={() => handleSubmit()}
                      isDisabled={isListening}
                      isLoading={isListening}
                      icon={
                        <Icon color="red.full" as={IoMdSend} boxSize="25px" />
                      }
                    />
                  }
                />
              </InputGroup>
              {currentUser?._profile?.data?.email && (
                <>
                  <Tooltip label="Toggle GPT4" placement="right">
                    <IconButton
                      bg={gpt4 ? "red.full" : "gray.100"}
                      aria-label="toggle GPT4"
                      onClick={() => setGpt4(prev => !prev)}
                      icon={
                        <Icon
                          color={gpt4 ? "white" : "dark.full"}
                          as={SiOpenai}
                          boxSize="25px"
                        />
                      }
                    />
                  </Tooltip>
                  <ReferenceChecks />
                </>
              )}
            </HStack>
          </Center>
        </Box>
        {showTopPrompts && (
          <Box>
            <Center w="100%" pb={[6, 6, 0]} pt={[0, 0, 4]} p={[2, 2, 0]}>
              <HStack
                w="full"
                marginTop={["", "", ""]}
                flexDirection="column"
                borderTop={isMobile ? "none" : "1px solid"}
                borderTopColor={isMobile ? "none" : "gray.200"}
              >
                <Footer />
              </HStack>
            </Center>
          </Box>
        )}
      </SimpleGrid>
    </Box>
  )
}

export default UserInput
