import { Box, Circle, HStack, Image, Text } from "@chakra-ui/react"
import React from "react"
import { useChat } from "./ChatProvider"

function TypingAnimation() {
  const { namespace } = useChat()
  return (
    <HStack
      w="full"
      h="full"
      position="relative"
      spacing={6}
      justifyContent="flex-end"
    >
      <Box pt={4}>
        <Box
          py={4}
          px={12}
          bg="white"
          roundedLeft="20px"
          roundedBottomRight="20px"
        >
          <Box
            flex="1"
            sx={{
              span: {
                width: "7px",
                height: "7px",
                backgroundColor: "gray.100",
                display: "inline-block",
                margin: "1px",
                borderRadius: "50%",
                "&:nth-of-type(1)": {
                  animation: "bounce 1s infinite",
                },
                "&:nth-of-type(2)": {
                  animation: "bounce 1s infinite .2s",
                },
                "&:nth-of-type(3)": {
                  animation: "bounce 1s infinite .4s",
                },
              },
              "@keyframes bounce": {
                "0%": {
                  transform: "translateY(0px)",
                },
                "50%": {
                  transform: "translateY(8px)",
                },
                "100%": {
                  transform: "translateY(0px)",
                },
              },
            }}
          >
            <span></span>
            <span></span>
            <span></span>
          </Box>
        </Box>
        <Text pt={1} fontSize="11px" w="full" textAlign="center">
          {namespace?.chatIcon?.title} is typing
        </Text>
      </Box>

      {namespace?.chatIcon?.show && (
        <Circle size={["32px", "32px", "50px"]} overflow="hidden">
          <Image
            src="https://res.cloudinary.com/wild-creative/image/upload/v1700034710/chatvz_fjim7f.svg"
            rounded="full"
            boxShadow="rgba(207, 51, 58, 0.1) 0px 10px 20px"
          />
        </Circle>
      )}
    </HStack>
  )
}

export default TypingAnimation
