import Section from "gatsby-theme-wild-child/src/components/Section"
import React from "react"
import BlobBackground from "./BlobBackground"
import UserInput from "./UserInput"
import { useChat } from "./ChatProvider"
import { Grid, Heading, Text, VStack } from "@chakra-ui/react"

function HeroSection() {
  const { namespace } = useChat()

  return (
    <Section
      minH={["100%", "100%", "100vh"]}
      h={["100%", "100%", "100vh"]}
      maxH={["100%", "100%", "100vh"]}
      bg="rgba(255, 255, 255, 0.9)"
      pt={["90px", "90px", "120px"]}
      zIndex="0"
      withContainer={false}
      overflow="scroll"
    >
      <Grid h="100%" gridRow={2} gridColumn={1} maxW="1180px">
        <VStack
          h="100%"
          w="full"
          mt={["10px", "45px", "50px"]}
          mb={[6, 6, 20]}
          spacing={[4, 4, 8]}
          minH={["unset", "unset"]}
        >
          <Heading
            fontSize={["4xl", "5xl", "6xl", "80px", "90px", "104px"]}
            textAlign="center"
            color="dark.full"
            as="h1"
            lineHeight={["0.7", "0.7", "0.85"]}
            letterSpacing="-30"
            fontWeight="bold"
          >
            {namespace?.title}
          </Heading>
          <Text
            fontWeight="400"
            fontSize={["17px", "19px", "20px", "20px", "20px", "23px"]}
            textAlign="center"
            lineHeight="1.3"
            letterSpacing="tight"
            px={[4, 4, 12, 12, 20]}
          >
            {namespace?.description}
          </Text>
        </VStack>
        <UserInput showTopPrompts={true} />
      </Grid>
      <BlobBackground />
    </Section>
  )
}

export default HeroSection
